class HomeView {
  #newNavItems = document.querySelectorAll(".navigation-item");
  #listIconMobile = document.querySelector(".ph-list");
  #navMenu = document.querySelector(".navigation-list");
  #copyrightPage = document.querySelector(".terms");
  #copyrightItems = document.querySelectorAll(".copyright-item");
  #copyrightSections = document.querySelectorAll(".copyright-div");
  #iconAcessibility = document.querySelector(".accessibility-icon");
  #accessibilitySlide = document.querySelector(".accessibility-slide");
  #fontUpIcon = document.querySelector(".font-up-icon");
  #fontDownIcon = document.querySelector(".font-down-icon");
  #greyIcon = document.querySelector(".grey-icon");
  #contrastIcon = document.querySelector(".contrast-icon");
  #lightBkgdIcon = document.querySelector(".light-bkgd-icon");
  #highlightTabIcon = document.querySelector(".highlight-tab-icon");

  mobileNav() {
    this.#listIconMobile.addEventListener("click", (e) => {
      this.#navMenu.classList.toggle("open");
    });
  }

  _scrollToSection(section, pos, time) {
    // const currentPos = window.scrollY;
    // let start = null;
    // if (time == null) time = 500;
    // (pos = +pos), (time = +time);
    // window.requestAnimationFrame(function step(currentTime) {
    //   start = !start ? currentTime : start;
    //   const progress = currentTime - start;

    //   if (currentPos < pos) {
    //     window.scrollTo(0, ((pos - currentPos) * progress) / time + currentPos);
    //   } else {
    //     window.scrollTo(0, currentPos - ((currentPos - pos) * progress) / time);
    //   }
    //   if (progress < time) {
    //     window.requestAnimationFrame(step);
    //   } else {
    //     window.scrollTo(0, pos);
    //   }
    // });

    section.scrollIntoView({
      behavior: "smooth",
      top: 0,
    });
  }

  listenScrollDowns() {
    this.#newNavItems.forEach((item) => {
      item.addEventListener("click", (e) => {
        const id = e.target.dataset.id;
        const section = document.querySelector(`#${id}`);
        this._scrollToSection(section);
        // this._highlightNav(id);
        // if (this.#navMenu.classList.contains("open"))
        this.#navMenu.classList.toggle("open");
      });
    });
  }

  _openCopyright() {
    this.#copyrightPage.classList.remove("hidden");
  }

  _closeCopyright() {
    this.#copyrightPage.classList.add("hidden");
  }

  toggleCopyright() {
    this.#copyrightItems.forEach((item) => {
      item.addEventListener("click", (e) => {
        // open terms page
        this._openCopyright();
        // get item id
        const id = e.target.dataset.id;
        // close page if click is main
        if (id === "main") {
          this._closeCopyright();
          return;
        }

        // close all previous id infos
        this.#copyrightSections.forEach((section) => {
          section.classList.add("hidden");
        });
        // insert relevant id info
        document.querySelector(`#${id}`).classList.remove("hidden");
        // window.scrollTo(0);
      });
    });
  }

  slideAccess() {
    this.#iconAcessibility.addEventListener("click", (e) => {
      // toggle accessibility slide
      this.#accessibilitySlide.classList.toggle("open");
    });
  }

  // ACCESSABILITY FUNCTIONS

  allowAccessibility() {
    this._fontUp();
    this._fontDn();
    this._greyScale();
    this._highContrast();
    this._lightBkgd();
    this._highlightTab();
  }
  _fontUp() {
    const fSize = document.getElementsByTagName("p");

    this.#fontUpIcon.addEventListener("click", (e) => {
      for (let i = 0; i < fSize.length; i++) {
        let currentSize = window
          .getComputedStyle(fSize[i], null)
          .getPropertyValue("font-size");

        // check limit size change
        const curSize = currentSize.slice(0, 2);
        if (Number(curSize) > 70) return;
        // check over

        fSize[i].style.fontSize = `${
          Number(currentSize.replace("px", "")) + 1
        }px`;
      }
    });
  }

  _fontDn() {
    const fSize = document.getElementsByTagName("p");
    this.#fontDownIcon.addEventListener("click", (e) => {
      for (let i = 0; i < fSize.length; i++) {
        let currentSize = window
          .getComputedStyle(fSize[i], null)
          .getPropertyValue("font-size");

        // check limit size change
        // const curSize = currentSize.slice(0, 2);
        // if (Number(curSize) < 50) return;
        // check over

        fSize[i].style.fontSize = `${
          Number(currentSize.replace("px", "")) - 1
        }px`;
      }
    });
  }

  _greyScale() {
    this.#greyIcon.addEventListener("click", () => {
      document.body.classList.toggle("greyscale");
    });
  }

  _highContrast() {
    this.#contrastIcon.addEventListener("click", () => {
      document.querySelectorAll(".high-contrast").forEach((el) => {
        el.classList.toggle("higher-contrast");
      });
      document
        .querySelector(".opacity-contrast")
        .classList.toggle("high-contrast-img");
    });
  }

  _lightBkgd() {
    this.#lightBkgdIcon.addEventListener("click", () => {
      document.querySelectorAll(".light-bkgd").forEach((el) => {
        el.classList.toggle("lighter-bkgd");
      });
      document
        .querySelector(".opacity-contrast")
        .classList.toggle("high-contrast-img");
    });
  }

  _highlightTab() {
    this.#highlightTabIcon.addEventListener("click", () => {
      document.querySelectorAll("a").forEach((href) => {
        href.classList.toggle("highlightHref");
      });
    });
  }
}

export default new HomeView();
// #html = document.querySelector("html");
// #navItems = document.querySelectorAll(".navitem");
// #languages = document.querySelector(".languages");
// #languageItems = document.querySelectorAll(".language-item");
// #sectionVideo = document.querySelector(".section-video");
// #sectionskyline = document.querySelector(".section-skyline");
// #sectionCompanyOverview = document.querySelector(".section-company-overview");
// #videoText = document.querySelector(".video-text");
// #scrollBtnOverview = document.querySelector(".scroll-button-overview");
// #scrollBtnSkyline = document.querySelector(".scroll-button-skyline");
// #currentLanguage = ["en"];
// #languageIds = document.querySelectorAll("[data-lang]");
// #inputName = document.querySelector(".input-name");
// #inputEmail = document.querySelector(".input-email");

// this.#scrollBtnOverview.addEventListener("click", () => {
//   this._scrollToSection(this.#sectionskyline);
// });

// this.#scrollBtnSkyline.addEventListener("click", () => {
//   this._scrollToSection(this.#sectionCompanyOverview);
// });

// this.#navItems.forEach((item) => {
//   item.addEventListener("click", (e) => {
//     const id = e.target.closest(".navitem").querySelector(".nav-link")
//       .dataset.id;
//     const section = document.querySelector(`#${id}`);
//     this._scrollToSection(section);
//     this._highlightNav(id);
//     if (this.#navMenu.classList.contains("open"))
//       this.#navMenu.classList.toggle("open");
//   });
// });

// _translate(language) {
//   this.#languageIds.forEach((text) => {
//     // html header
//     this.#html.dir = langFile[language].dir;
//     this.#html.lang = langFile[language].lang;

//     // move language bar on phone
//     if (window.innerWidth < 763) {
//       if (language === "he") {
//         this.#languages.classList.add("rtl");
//         this.#languages.classList.remove("ltr");
//       }
//       if (language !== "he") {
//         this.#languages.classList.remove("rtl");
//         this.#languages.classList.add("ltr");
//       }
//     }

//     // document text
//     text.textContent = langFile[language][text.dataset.lang];

//     // update current language
//     this.#currentLanguage[0] = language;

//     // translate placeholders
//     this.#inputName.placeholder = langFile[language].name;
//     this.#inputEmail.placeholder = langFile[language].email;
//   });
// }

// flipLanguage() {
//   this.#languages.addEventListener("mouseover", (e) => {
//     // reveal other lang
//     this.#languageItems.forEach((item) => {
//       item.addEventListener("click", (e) => {
//         this._translate(e.target.id);
//       });
//       // change background color to highight
//       item.style.backgroundColor = "#1a3d5c";
//       if (item.classList.contains("hidden")) item.classList.remove("hidden");
//     });
//   });
//   this.#languages.addEventListener("mouseout", (e) => {
//     // return normal color
//     this.#languageItems.forEach((item) => {
//       item.style.backgroundColor = "#1a293c";
//     });

//     // find what current language is and hide the other
//     this.#languageItems.forEach((item) => {
//       if (item.id === this.#currentLanguage[0]) return;
//       if (item.id !== this.#currentLanguage[0]) item.classList.add("hidden");
//     });
//   });
// }

// toggleText(state) {
//   if (state === "visible") {
//     this.#videoText.classList.add("visible");
//   }
//   if (state !== "visible") {
//     this.#videoText.classList.remove("visible");
//   }
//   this.#videoText.classList.toggle("visible");
// }

// handleIntersection(entries) {
//   document.querySelector(".shadow-component").classList.toggle("visible");

//   document.querySelector(".video-text").classList.toggle("visible");
//   document
//     .querySelector(".scroll-button-overview")
//     .classList.toggle("visible");

//   // document
//   //   .querySelector(".video-block-component")
//   //   .classList.toggle("shadow-component");
// }

// observeTopScroll() {
//   const observer = new IntersectionObserver(this.handleIntersection, {
//     root: null,
//     rootMargin: "0px",
//     threshold: 0.05,
//   });

//   observer.observe(this.#sectionskyline);
// }
