import HomeView from "./homeView.js";

const init = function () {
  // HomeView.flipLanguage();
  HomeView.mobileNav();
  HomeView.listenScrollDowns();
  HomeView.toggleCopyright();
  HomeView.slideAccess();
  HomeView.allowAccessibility();
  // HomeView.observeTopScroll();
};

init();
